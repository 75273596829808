<template>


  <div class="all" id="all">





    <Head :top="top" :grxxff="grxxff" :xx="xx"></Head>
    <div class="list" :class="tabbarBoxFixed?'bslist':''" id="list">



      <div class="fwb">
        <div v-if="knowin['code']!='0000'">
          <el-empty image="http://viki.js-exp.com/public/images/qx.png" :description="msg"></el-empty>
        </div>

        <div class="shuiyin"  v-if="knowin['code']=='0000' && knowin.grade>=2">
          <div class="name" v-for="item in 220">{{knowin.jsusers_name}}</div>
        </div>

        <div class="f-head"  v-if="knowin['code']=='0000'" >
           <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
             <el-breadcrumb-item>{{knowin.one}}</el-breadcrumb-item>
             <el-breadcrumb-item>{{knowin.two}}</el-breadcrumb-item>
             <el-breadcrumb-item>{{knowin.name}}</el-breadcrumb-item>
           </el-breadcrumb>
        </div>

         <!-- 类型1 -->
         <!-- 类型:0=文本,1=图文,2=图片视频,3=api -->
         <block v-if="knowin.type==0 && knowin['code']=='0000'">
            <div class="con" style="text-align: center;">
              <div v-html="knowin.content" ></div>
            </div>
         </block>
         <!-- 类型2 -->
         <block v-if="knowin.type==1 && knowin['code']=='0000'">

           <div class="con" style="text-align: center;">

            <!--  <el-carousel class="tp" indicator-position="outside">
               <el-carousel class="tp"-item v-for="item in  knowin['listimg']" :key="item">
                  <img  :src="'http://viki.js-exp.com/public'+item"/>
               </el-carousel-item>
             </el-carousel> -->


              <el-carousel class="tp" :interval="4000" type="card" height="20rem" loop="true">
                <el-carousel-item v-for="item in knowin['listimg']" :key="item">
                  <!-- <h3 class="medium">{{ item }}</h3> -->
                  <img  :src="'http://viki.js-exp.com/public'+item"/>
                </el-carousel-item>
              </el-carousel>

                <el-carousel class="zc" indicator-position="outside">
                  <el-carousel-item v-for="item in knowin['listimg']" :key="item">
                     <img  :src="'http://viki.js-exp.com/public'+item"/>
                  </el-carousel-item>
                </el-carousel>

              <div v-html="knowin.content" ></div>
           </div>
         </block>

         <!-- <video class="video" loop="" muted="" src="https://prod-streaming-video-msn-com.akamaized.net/a75a7d73-21ab-4ac9-8c30-890433965c24/e9f6bdcb-eba0-4eca-b9d2-60d3415bf65f.mp4" poster="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAOEa3q.img" style=""></video> -->
         <!-- 类型3 -->
         <block v-if="knowin.type==2 && knowin['code']=='0000'">
           <div class="con" style="text-align: center;">
            <el-carousel class="tp" :interval="4000" type="card" height="20rem" loop="true">
              <el-carousel-item v-for="item in knowin['listimg']" :key="item">
                <!-- <h3 class="medium">{{ item }}</h3> -->
                <img  :src="'http://viki.js-exp.com/public'+item"/>
              </el-carousel-item>
            </el-carousel>

            <el-carousel class="zc" indicator-position="outside">
              <el-carousel-item v-for="item in knowin['listimg']" :key="item">
                 <img  :src="'http://viki.js-exp.com/public'+item"/>
              </el-carousel-item>
            </el-carousel>

            <div v-html="knowin.content" ></div>
            <div class="video">
              <video v-for="item in knowin['listvideo']" :src="'http://viki.js-exp.com/public'+item" controls ></video>
            </div>
           </div>
         </block>

        <!-- 类型:0=文本,1=图文,2=图片,3=api,4=视频,5=文字视频,6=Word等文件 -->

         <block v-if="knowin.type==4 && knowin['code']=='0000'">
           <div class="con" style="text-align: center;">
            <div class="video">
              <video v-for="item in knowin['listvideo']" :src="'http://viki.js-exp.com/public'+item" controls ></video>
            </div>
           </div>
         </block>

         <block v-if="knowin.type==5 && knowin['code']=='0000'">
            <div class="con" style="text-align: center;">
             <div v-html="knowin.content" ></div>
             <div class="video">
               <video v-for="item in knowin['listvideo']" :src="'http://viki.js-exp.com/public'+item" controls ></video>
             </div>
            </div>
         </block>

       <block v-if="knowin.type==6 && knowin['code']=='0000'">

          <div class="z-all">


               <div class="z-a-one" v-for="(item,index) in knowin['listvideo']" >
                    <div class="z-a-top">
                      <img class="img-one1" src="~assets/download/xz1.png" />
                      <img class="img-two1"  src="~assets/download/xz0.png" />
                    </div>
                    <div class="z-a-title">{{knowin.name}}</div>
                    <div class="xz" @click="don(item)" ><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />文件{{index}}</div>
                    <!-- <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div> -->
                </div>

              <!--  <div class="z-a-one">
                    <div class="z-a-top">
                      <img class="img-one1" src="~assets/download/xz1.png" />
                      <img class="img-two1"  src="~assets/download/xz0.png" />
                    </div>
                    <div class="z-a-title">xxx会议报名资料</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                </div>

                <div class="z-a-one">
                    <div class="z-a-top">
                      <img class="img-one1" src="~assets/download/xz1.png" />
                      <img class="img-two1"  src="~assets/download/xz0.png" />
                    </div>
                    <div class="z-a-title">xxx会议报名资料</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                </div>

                <div class="z-a-one">
                    <div class="z-a-top">
                      <img class="img-one1" src="~assets/download/xz1.png" />
                      <img class="img-two1"  src="~assets/download/xz0.png" />
                    </div>
                    <div class="z-a-title">xxx会议报名资料</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                </div>

                <div class="z-a-one">
                    <div class="z-a-top">
                      <img class="img-one1" src="~assets/download/xz1.png" />
                      <img class="img-two1"  src="~assets/download/xz0.png" />
                    </div>
                    <div class="z-a-title">xxx会议报名资料</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                    <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
                </div -->


        </div>
      </block>

      </div>
      <div class="left">
        <div class="tuijian">
          <span class="tjx">关键词</span>
          <div class="t-one" v-for="item in hot" @click="goTo('search',item.name)" >
            <img  src="../../assets/cloud/sousuo.png" />
            <div>{{item.name}}</div>
          </div>
         <!-- <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div>
          <div class="t-one">
            <img  src="../../assets/cloud/sousuo.png" />
            <div>推荐</div>
          </div> -->
        </div>


        <div class="bottom">
          <div class="tuijian">
            <span class="tjx">推荐知识</span>
            <div class="l-one" v-for="item in jsknowlist">
              <div class="l-o-img"  @click="ins(item.id)">
                <img :src="'http://viki.js-exp.com/public/'+item.fimage" />
              </div>
              <div class="l-o-con"  @click="ins(item.id)">
                <div class="bt">{{item.name}}</div>
                <div class="jj">{{item.jianjie}}</div>
                <div class="sj">{{item.time}}</div>
              </div>

            </div>

           <!-- <div class="l-one">
              <div class="l-o-img">
                <img  src="../../assets/my/ban.png"/>
              </div>
              <div class="l-o-con">
                <div class="bt">标题</div>
                <div class="jj">简介简介简介简介简介简介</div>
                <div class="sj">2021-00-01</div>
              </div>

            </div>

            <div class="l-one">
              <div class="l-o-img">
                <img  src="../../assets/my/ban.png"/>
              </div>
              <div class="l-o-con">
                <div class="bt">标题</div>
                <div class="jj">简介简介简介简介简介简介</div>
                <div class="sj">2021-00-01</div>
              </div>

            </div>

            <div class="l-one">
              <div class="l-o-img">
                <img  src="../../assets/my/ban.png"/>
              </div>
              <div class="l-o-con">
                <div class="bt">标题</div>
                <div class="jj">简介简介简介简介简介简介</div>
                <div class="sj">2021-00-01</div>
              </div>

            </div> -->




          </div>
        </div>
      </div>




    </div>


  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getHome,getbanner} from 'network/home.js';
import {useRouter} from 'vue-router';

 import {
    getpaiHang,
    gethotCi,
    getin
  } from 'network/examination.js';


import Head from "components/content/headder/Head.vue";

export default {
  name: 'Search',
  data() {
    return {
      msg:'权限不足IP限制',
      hot:[],
      knowin:[],
      know:[],
      jsknowlist:[],
      id:0,
      xx:0,
      input:'',
      tabbarBoxFixed:false,
    }
  },
  setup(){
    const top=2;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })

    })
    //跳转方法
    const goTo=(path,fl)=>{

       var query = {
         'name': fl
       }
      router.push({
        path: path,
        query: query || {}
      })
    }

    return { databa,ab,goTo,top }
  },

  methods: {
    // tiaozhuan(name){
    //   uni.na
    // },
    don(donw){
      window.location.href='http://viki.js-exp.com/public'+donw;
    },
    paiHang(){
       var that = this;
       var data = {
       };
       getpaiHang(data).then(res => {
         console.log(res);
         console.log('ph');
         console.log(res.data.msg);

         that.ph=res.data;
         that.know=res.data;

         // if(that.know.grade=='1'){

         //   if(that.know.code=='0001'){
         //      window.location.href="http://viki.js-exp.com/public/uPqdLSmiBQ.php/index/login";
         //    }

         // }
       }).catch(err => {})
    },
    ins(id=''){
       var that = this;
       if(id==''){
         var data = {
           id:that.id
         };
       }else{
         var data = {
           id:id
         };

       }

       getin(data).then(res => {
         that.knowin=res.data;
         console.log(that.knowin.type);
         console.log(that.knowin.apiUrl);
         console.log('ccccccc');
         that.msg=that.knowin.msg;
         console.log('ccccccc');
         if(that.knowin.type=='3'){
           window.open(that.knowin.apiUrl);
           window.history.back()
         }
         that.jsknowlist=res.data.jsknowlist;
         // if(res.data.type=='3'){

         // }
       }).catch(err => {})
    },

    //滚动事件
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var offsetTop = document.querySelector('#list').offsetTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
      console.log(scrollTop,offsetTop)
      if(offsetTop>=578){
        this.tabbarBoxFixed = true
      }else{
        this.tabbarBoxFixed = false
      }
      // if (scrollTop > offsetTop) {
      //   this.tabbarBoxFixed = true
      //   // console.log('1');
      // } else {
      //   this.tabbarBoxFixed = false
      //   // console.log('2');
      // }
    },

  hotCi(){
       var that = this;
       var data = {
       };
       gethotCi(data).then(res => {
          that.hot=res.data;
       }).catch(err => {})
    },
  },
  mounted() {
    // 向页面添加股东事件
    window.addEventListener('scroll', this.handleScroll, true);
    this.paiHang();


    var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
    url = '?' + url;


    console.log(url);
    var object = {};
    if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
    {
      var str = url.substr(1); //得到?后面的字符串
      var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      for (var i = 0; i < strs.length; i++) {
        object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      }
    }
    console.log(object);
    this.id=object['id'];
    this.ins('');
    this.hotCi();

    window.addEventListener("copy", function (e) {
      return false;
    });

  },
  destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },

  components:{
    Head,
  },
}
</script>
<style lang="scss" scoped="scoped">
  .all{
    user-select: none;
  }


  .a-cen{
    width: 100vw;
    background: #fff;
    padding: 1rem 0;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
    }
  }
  .zong{
    z-index: 9999999!important;
    position: relative;
    min-height: 94vh;
    .huanhang{
      height: 5vh;
    }
    .gjc{
       display: flex;
       align-items: center;
       color: #fff;
       width: 90%;
       margin: 0 auto;
       margin-top: 1.5625rem;
       justify-content: center;
       flex-wrap: wrap;
      .g-one{
         text-align: center;
         padding: 10px 27px;
         cursor: pointer;
         font-size: 0.8rem;
         position: relative;
         margin: 0 8px;

        img{
          width: 3.125rem;
        }

        .jp{
          position: absolute;
          top: 0;
          right:0;
          img{
            width: 2.3rem;
          }
          .numdw{
            position: relative;
            .num{
              position: absolute;
              top: 57%;
              left: 51%;
              transform: translate(-50%, -50%);
              color: #555;
            }
          }

        }
      }

      .g-one:hover{
        // background: rgba(255,255,255,0.3);
        border-radius: 5px;
        background: #064add;
        color:#fff;
      }
    }




    // .wm{
    //   position: sticky;
    //   top: 70px;
    // }
    .sousuo{
      width: 68%;
      margin: 0 auto;

      z-index: 999999999;
      top: 4.625rem;
         width: 60%;
         border-radius: 59px!important;
         background-color: #f5f5f5; /*设置输入框背景颜色，可以根据需求修改*/
         margin: 0 auto;
         text-align: center;
         padding: 5px;
         position: sticky;
         top: 4.975rem;
         .dw{
           position: absolute;
           top: 50%;
           left: 5%;
           transform: translate(-50%,-50%);
           width: 1.25rem;
         }
         .dw1{
           position: absolute;
           top: 50%;
           right: 3%;
           transform: translate(-50%,-50%);
           width: 1.555rem;
           cursor: pointer;
         }
       .inp {
          border: none; /*去掉输入框边框*/
          background-color: #f5f5f5; /*设置输入框背景颜色，可以根据需求修改*/
          // padding: 5px 30px!important; /*设置输入框内边距，增加输入体验*/
          border-radius: 59px; /*设置输入框边框弧度，使其更加美观*/
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /*添加投影，增强视觉效果*/
          transition: all .3s ease; /*添加动画效果，当我们鼠标移入时有更流畅的过渡效果*/
          width: 98%;
          padding: 8px 60px!important;
          margin: 0 auto;

        }
       .inp:focus {
          outline: none; /*去除输入框选中时出现的蓝色边框*/
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /*增加投影效果，使输入框更加明显*/
        }



    }
  }
  .list{
    padding: 0.875rem;
    width: 100%;
    // height: 120vh;
    margin-top: 1.875rem;
    position: sticky;
    top: 8.6875rem;
    // background: #fff;
    margin: 0 auto;
    margin-top: 1.275rem;
    min-height: 88vh;
    display: flex;
    justify-content: space-between;
  }

  .bslist{
    background: #fff;
  }
  .bj{
     width: 100%;
     min-height: 100vh;
     background: url(http://localhost:8080/public/img/bj.d498e236.jpg) no-repeat;
     background-size: 100%;
     background-attachment: fixed;


     // background-position: center center;
     // background-repeat: no-repeat;
     // background-size: cover;
     // height: 101%;
     // margin: -1px 0px 0px -1px;
     // object-fit: cover;
     // padding: 0px;
     // position: absolute;
     // width: 101%;


     .overlayGradient{
        height: 160vh;
        opacity: 0.6;
        position: absolute;
        top: 0px;
        transition: opacity 0.3s linear 0s;
        width: 100%;
        z-index: 99;
        // background: #fff;
        background-image: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.3) 166%);
     }
  }
  .el-input{
    .el-input__wrapper{
      padding: 8px!important;
      border-radius: 59px!important;
    }
  }

  .list{
      // padding: 1.875rem;
      .l-one{
        border-radius: 0.22rem;
        // background: #ffffff66;
        // background: #fff;
        border-bottom: 0.0625rem solid #E8E8E8;
        padding: 0.4rem 0.4rem;
        margin-bottom:1rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        align-items: flex-start;
        .l-left{
          // width: 100%;
          display: flex;
          img{
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            margin-right: 0.625rem;
          }
          .l-nr{
            .l-n-bt{
              font-weight: bold;
              margin-bottom: 0.75rem;
              margin-top: 0.5rem;
              font-size: 1.2rem;
            }
            .l-n-nr{
              // white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              font-size: 0.8rem;
              color: #000;
            }
          }
        }
        .shoucang{
          position: absolute;
          top: 0.825rem;
          right: 0.825rem;
          // display: inline-block!important;
        }
        .l-right{
            position: absolute;
            bottom: 0.825rem;
            right: 0.825rem;

          display: flex;
          align-items: center;
          color: #999999;
          img{
            width: 1.75rem;
            margin-right: 0.625rem ;
          }

        }
      }
  }
  .l-one:hover{
      // background: #064addd6;
      // background: rgb(36 88 201 / 81%);
      background: rgba(255, 255, 255, 0.4);
      background: #064add;
      color:#fff;
      color: #fff;
      .l-n-nr{
        color: #fff!important;
      }
      .lll-list{
        color: #fff!important;
      }

      .pi2{
        display: inline-block!important;
      }
      .pi1{
        display: none!important;
      }

  }
  .l-right>img{
    position: absolute;
    top: -0.6875rem;
  }
.l-one{
  width: 100%;
  margin: 0 auto;
      cursor: pointer;
      flex-wrap: wrap;
      .l-left{
        // width: 100%!important;
      }
      .l-right{
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }
 .zxr{

    font-size:0.7rem;
    margin-top: 0.425rem;

    .pepolist{
       display: flex;
       .pepo{
         text-align: center;
         margin-top: 0.625rem;
         margin-right: 0.625rem;
       }
       img{
         font-size:0.7rem;
         width: 2rem!important;
         height: 2rem!important;
         margin-right: 0.125rem!important;
       }

    }

  }
  .lll{
     display: flex;
     align-items: center;
     margin-top: 0.425rem;

     .lll-list{
       display: flex;
       // align-items: center;
       font-size:0.8rem;
       margin-right: 0.825rem!important;
       color:#000;
       img{
         width: 0.8rem!important;
         height: 0.8rem!important;
         margin-right: 0.125rem!important;
       }
     }
   }
  .img{
    width: 21%;
    img{
      width: 100%;
    }
  }
  .l-left{
    width: 77% !important;
    margin-left: 1.5%;
  }

  .fwb{
    width: 77% ;
    // background:green;
    background: #fff;
    padding-top: 0.9rem;
     position: relative;
     overflow: hidden;
  }
  .left{
    width: 22%;
    background: #fff;
    padding-top: 0.9rem;
    .top{

    }
  }

  .tuijian{
    display: flex;
    align-items: center;
    // color: #fff;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1.5625rem;
    // justify-content: center;
    flex-wrap: wrap;
    // background: rgba(0,0,0,0.4);
    padding: 0.825rem 0.825rem 0 0.825rem;
    font-size: 0.8rem;
    border-radius: 0.3375rem;

    .t-one{
      display: flex;
      align-items: center;
      padding: 0.3rem 0.625rem;
      // width: 10%;
      overflow: hidden;
      img{
        width: 1rem;
        margin-right: 0.625rem;
      }
      cursor: pointer;
      margin-bottom: 0.625rem;
    }
    .t-one:hover{
      background: #064add;
      color:#fff;
      // rgba(255,255,255,0.3);
      border-radius: 5px;
    }
  }
  .tjx{
    margin-bottom:1rem;
    font-size:1rem;
    width: 100%;
  }
  .l-o-img{
    width: 45%;
    img{
      width: 100%;
    }
  }
  .l-o-con{
    width: 52%;
    .bt{
      font-size:0.8rem;
    }
    .jj{
      min-height: 1.4rem;
      margin: 0.4rem 0;

      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: 0.8rem;
      // color: #888888;


    }
    .jj,.sj{
      font-size:0.6rem;
    }
  }

  .con{
    padding: 1rem 1rem 1rem 1rem ;
    .video{
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      video{
        width: 31%;
        height: 13rem;
        margin: 1%;
      }
    }
  }
  .f-head{
    margin-left: 1rem;
  }
   .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }

    .z-all{
      width: 95%;
      margin: 0 auto;
      margin-top: 30px;
      // width: var(--allWidth);
      // margin: 0 auto;
      margin-bottom: 1.875rem;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .z-a-one:hover{
         .z-a-top{
           background: #fff;
         }
        .img-two1{

          display: block;
        }
        .img-one1{
          display: none;
        }

        background: #2E9BD6;

        .z-a-title{
          color: #fff!important;
        }

        .xz{
          background: #2E9BD6;
          color: #fff;
          .img-one{
            display: none;
          }
          .img-two{
            display: block;
          }
           border: 0.0625rem solid #fff;

        }

      .xz:hover{
          background: #fff;
          color: #2E9BD6;

          .img-one{
            display: block;
          }
          .img-two{
            display: none;
          }


        }


      }
      .z-a-one{
        .img-two1{
          display: none;
        }
        width: 22%;
        min-height: 3.875rem;
        margin-right: 4%;
        background: 0;
        box-shadow: 0.125rem 0.125rem 0.8125rem rgb(0 0 0 / 30%);
        border-radius: 0.3125rem;
        margin-bottom: 1.25rem;
        text-align: center;
        padding-bottom: 1rem;
        .z-a-top{
          margin-top: 1.375rem;
          margin-bottom: 30px;
          padding: 0.625rem;
          border: 1px solid #A1A1A1;
          border-radius: 50%;
          display: inline-block;
          img{
            width: 2.75rem;

          }
        }
        .z-a-title{
          font-size: 1.1rem;
          font-weight: bold;
          color: #191919;
          margin-bottom: 1.875rem;
        }
        .xz{
          width: 70%;
          margin: 0 auto;
          background: #fff;
          border: 0.0625rem solid #2E98D6;
          padding: 0.875rem;
          margin-bottom: 0.675rem;
          font-size: 0.8125rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #2E9BD6;
          cursor: pointer;
          img{
            width: 1rem;
            margin-right: 0.5rem;
          }
          .img-two{
            display: none;
          }
        }


      }
      .z-a-one:nth-child(4n){
        margin-right: 0%;
      }

    }

    .zc{
      display:none;
    }
   @media screen and (max-width: 1300px) {
    .left{
      display:none;
    }
    .zc{
      display:block;
    }
    .fwb{
      width: 100%!important ;

    }
    .tp{
      
      display:none!important;
    }
    .video{
      video{
        width: 100%!important;
        margin: 0!important;
        height: auto;
        margin-bottom: 0.625rem;
      }
    }
   }
   .shuiyin{
     z-index: 9999999999;
       position: absolute; /* 固定定位 */
       top: 0%; /* 垂直居中 */
       left: 0%; /* 水平居中 */
       // transform: translate(-50%, -50%); /* 居中定位 */
       font-size: 14px; /* 字体大小 */
       color: #ccc; /* 颜色 */
       opacity: 0.5; /* 透明度 */
       z-index: 1; /* 层级，-1表示置于底层 */
       display: flex;
       flex-wrap: wrap;
       width: 100%;
       pointer-events:none;
       /* justify-content: space-between;             */
           justify-content: space-between;
   }
   .name{
       /* 水印文字 */
       /* content: "我的水印";  */
       transform: skew(-30deg,30deg);
       /* margin-bottom: 35px; */
       margin: 40px;
   }
</style>
